import { useState } from 'react';
import Link from 'next/link';
import Image from 'next/image';
import { Button } from '@/components/ui/button.tsx';
import { Dialog, DialogContent, DialogDescription, DialogHeader, DialogTrigger } from '@/components/ui/dialog.tsx';
import { Mail } from 'react-feather';
import { Input } from '@/components/ui/input.tsx';
import { Textarea } from '@/components/ui/textarea.tsx';
import { Form, FormControl, FormDescription, FormField, FormItem, FormLabel } from '@/components/ui/form.tsx';
import { z } from 'zod';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { UserApi } from '../../../services';

export default function ContactSupportModal() {
  const [emailSent, setEmailSent] = useState(false);
  const [loading, setLoading] = useState(false);
  const contactUsFormSchema = z.object({
    email: z.string().email({
      message: 'Please enter a valid email address',
    }),
    message: z.string().min(10, {
      message: 'Please leave a more detailed message',
    }),
  });

  const contactUsForm = useForm({
    resolver: zodResolver(contactUsFormSchema),
    defaultValues: {
      email: '',
      message: '',
    },
  });

  const {
    formState: { errors },
    handleSubmit,
  } = contactUsForm;

  const onSubmit = async values => {
    setLoading(true);
    const { email, message } = values;
    try {
      await UserApi.sendSupportMessage({
        email,
        message,
      });
      setEmailSent(true);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };
  return (
    <Dialog>
      <DialogTrigger asChild>
        <Button variant="link" className="underline text-primary-500 pl-0">
          Contact support
        </Button>
      </DialogTrigger>
      <DialogContent
        className={`sm:max-w-[80%] md:max-w-[70%] lg:max-w-[40%] min-h-[400px] flex flex-col items-stretch justify-stretch px-0`}>
        <DialogHeader className="flex justify-center mt-8">
          {!emailSent ? (
            <>
              <h2 className="display-lg mx-auto">Contact us</h2>
              <DialogDescription className="w-[60%] mx-auto text-center">
                Please leave your email and a detailed message. Our support team will be in touch as soon as possible.
              </DialogDescription>
            </>
          ) : (
            <div className="flex flex-col items-center gap-5">
              <div className="bg-success-100 w-20 h-20 flex items-center justify-center rounded-full">
                <Image
                  src="/static/images/MailCheck.svg"
                  height={50}
                  width={50}
                  className="text-success-500"
                  alt="mail icon"
                />
              </div>
              <h2 className="text-center display-lg">Message received</h2>
              <DialogDescription className="text-lg text-center w-[70%] mb-3">
                We&apos;ve received your message and will be in touch as soon as we can. Thank you for your patience.
              </DialogDescription>
            </div>
          )}
        </DialogHeader>
        {!emailSent ? (
          <Form {...contactUsForm}>
            <form onSubmit={handleSubmit(onSubmit)} className="gap-4 mb-6 flex flex-col items-center">
              <FormField
                className=""
                control={contactUsForm.control}
                name="email"
                render={({ field }) => (
                  <FormItem className="text-left w-[65%]">
                    <FormLabel className="text-xs text-gray-500">Email</FormLabel>
                    <FormControl
                      className={`focus:outline-none active:outline-none ${errors?.email && 'border-error-500'}`}>
                      <Input
                        icon={<Mail className="h-[16px] w-[16px] text-gray-400" />}
                        type="email"
                        placeholder="john.doe@gmail.com"
                        error={errors?.email}
                        aria-invalid={errors?.email ? 'true' : 'false'}
                        {...field}
                        className="focus:outline-none active:outline-none"
                      />
                    </FormControl>
                    {errors?.email && (
                      <FormDescription className="text-error-500 text-xs">*{errors?.email.message}</FormDescription>
                    )}
                  </FormItem>
                )}
              />
              <FormField
                className=""
                control={contactUsForm.control}
                name="message"
                render={({ field }) => (
                  <FormItem className="text-left w-[65%]">
                    <FormLabel className="text-xs text-gray-500">Your message</FormLabel>
                    <FormControl
                      className={`focus:outline-none active:outline-none ${errors?.message && 'border-error-500'}`}>
                      <Textarea
                        placeholder="Tell us how we can help"
                        error={errors?.message}
                        aria-invalid={errors?.message ? 'true' : 'false'}
                        {...field}
                        className="h-[150px] resize-none focus:outline-none active:outline-none"
                      />
                    </FormControl>
                    {errors?.message && (
                      <FormDescription className="text-error-500 text-xs">*{errors?.message.message}</FormDescription>
                    )}
                  </FormItem>
                )}
              />
              <Button
                className="text-black bg-accent-300 cursor-pointer hover:bg-accent-200 my-6 w-[65%]"
                size="sm"
                disabled={loading}>
                Send email
              </Button>
            </form>
          </Form>
        ) : (
          <div className="mx-auto">
            <Link href="/login">
              <span className="hover:cursor-pointer text-center font-bold">Back to login</span>
            </Link>
          </div>
        )}
      </DialogContent>
    </Dialog>
  );
}
